<template>
    <div>
        <select-user ref="selectUser" :masterUsers="masterUsers" @selectUser="selectUser"></select-user>
    </div>
</template>

<script>
import SelectUser from './components/select-user';
import {getMenuList} from '@/assets/js/commonApi';
export default {
    components:{SelectUser},
    data(){
        return{
            params:{
                token: this.$route.query.token,
                encryptId: this.$route.query.id,
            },
            loading: null,
            redirect: this.$route.query.redirect || '/manage/home',
            masterUsers:[]
        }
    },
    created(){
        this.loading = this.$loading({
            lock: true,
            text: '自动登录中...',
            // spinner: 'el-icon-loading',
            background: 'rgba(225, 225, 225, 0.9)'
        });
        this.getLoginUser();
    },
    methods:{
        async getLoginUser(){
            const self = this;
            this.$store.commit( 'setCUSUT', this.sign );

            this.$request({
                url: "/user/login/adminAutoLogin",
                method: "POST",
                params: {...this.params,systime:new Date().getTime()},
            })
                .then((res) => {
                    const { state,result, msg } = res.data;
                    if (state == 1 && result) {
                        self.$store.commit( 'setCUSUT', result.session.token || null );
                            
                        if(result.isMaster != 1 && result.isChoose == 1){
                            this.masterUsers = result.masterUsers;
                            this.$refs.selectUser.show();
                        }else{
                            if( result.masterUsers.length > 0 ){
                                self.selectUser(result.masterUsers[0]);
                            }else{
                                self.selectUser(result.session);
                            }
                        }

                        // if((result.isMaster == 1 || result.masterUsers.length == 1) && result.isChoose != 1){ //主账号或主账号只有一个主账号
                        //     self.selectUser(result.masterUsers[0]);
                        // }else{ // 子账号且有多个主账号
                        //     this.masterUsers = result.masterUsers;
                        //     this.$refs.selectUser.show();
                        // }
                    }else{
                        this.$message.error(msg || '登录失败,重新登录');
                        this.$router.push('/login');
                    }
                })
                .catch(() => {
                    this.$message.error( '登录失败,重新登录');
                    this.$router.push('/login');
                }).finally( () => {
                    this.loading.close();
                });
        },
        async selectUser(item){
            this.$store.commit('setUserInfo',item);
            await getMenuList();//获取登录用户拥有的菜单权限
            if( item.userType == 1 || item.type == 1 ){
                this.$router.push('/');
            }else{
                this.$router.push(this.redirect);
            }
        }
    }
}
</script>